<template>
  <section-place-info
    :business="business"
    :place="place"
    :title="translations.contactPlaceInfoFifthTitle"
    scroll-id="placeFifthCity"
  />
</template>

<script>
  import SectionPlaceInfo from '@/views/sections/reusable/PlaceInfo'
  import { latLng } from 'leaflet'
  import { mapState } from 'vuex'
  import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
  import { STORE_NAMESPACE_CONTACT } from '@/views/contact/Index.vue'

  export default {
    name: 'SectionContactPlaceInfoFifth',
    components: {
      SectionPlaceInfo,
    },
    computed: {
      ...mapState(STORE_NAMESPACE_CONTACT, [
        'translations',
      ]),
      business () {
        return [
          {
            icon: 'mdi-map-marker-outline',
            title: 'Address',
            text: this.translations?.contactPlaceInfoFifthLocale,
          },
          {
            icon: 'mdi-cellphone',
            title: 'Phone',
            text: documentToHtmlString(this.translations?.contactPlaceInfoFifthPhones),
          },
          {
            icon: 'mdi-email',
            title: 'Email',
            text: this.translations?.contactPlaceInfoFifthEmail,
          },
        ]
      },
    },
    data: () => ({
      place:
        {
          geo: latLng(50.043430, 19.275210),
          address: 'ul. Lipowa 5 \b 32-600 Oświęcim',
        },

    }),
  }
</script>
